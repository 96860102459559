import {PKCE} from "./adB2c.js"


const ADB2C_STATE_KEY = "AADState"


export function parseFragment() {
	/**
	 * TODOs
	 * - handle multi parameters and objects
	 * - accept string 'query' as argument
	 */
	var t, q = {}; 
	for(var p of document.location.hash.slice(1).split("&")){
		t = p.split("=")
		q[t[0]] = t[1]
	}
	return q
}


export async function executeFlow(client, redirectUri, callback) {

	const fragment = parseFragment()
	const token = await ADB2C.persistence.getRefreshToken()

	if( "error" in fragment) {

		console.error(fragment["error"])
		console.log(fragment)
		// TODO: raise exception ?

	}
	else if(token != null && Date.now() < token.expires) {

		// Using refresh token
		console.log("'Refresh token' found in storage. Retrieving tokens information by 'refresh token'.")
		var info = await client.getTokenInfoByRefreshToken(token.refreshToken)
		await processTokensInfo(info, client, callback)

	}
	else if(!("code" in fragment)) {

		// Redirect to login page
		console.log("'Code' not found in fragment. Redirecting to login page.")
		// Generates PKCE keys
		const pkce = await PKCE.getKeys()
		// Saves the application state
		const stateId = crypto.randomUUID()
		const state = {
			codeVerifier: pkce.codeVerifier
		}
		sessionStorage.setItem(stateId, JSON.stringify(state))
		// Generates login URL
		var url = client.getLoginUrl(redirectUri, pkce, {
			state: stateId,
			response_type: "code",
			response_mode: "fragment",
			scope: "openid"
		})
		// Redirects
		location.href = url

	}
	else {

		// Using code
		console.log("'Code' found in fragment. Retrieving token information by 'code'.")
		// Retrieves state information
		var state = JSON.parse(sessionStorage.getItem(fragment.state))
		// Gets tokens information
		var info = await client.getTokenInfoByCode(fragment.code, state.codeVerifier)
		// Clears state information
		sessionStorage.removeItem(fragment.state)
		// Clears URL fragment
		location.hash = "#"
		await processTokensInfo(info, client, callback)

	}

}


async function processTokensInfo(info, client, callback) {
	if("error" in info) {
		console.error("Unable to process token info. Token won't be refreshed.")
		console.error(info)
	}
	else {
		// Calculates token expiration
		var expires = 1000 * (info.not_before + info.id_token_expires_in)
		console.log("Access token expires at ", expires)
		console.log("Access token expires in ", expires - Date.now())
		// Stores the refresh token
		await ADB2C.persistence.saveRefreshToken(info.refresh_token, expires)
		// Set timeout to refresh the id_token one minute before it expires
		window.setTimeout(async (client, refreshToken, callback) => {
			processTokensInfo(
				await client.getTokenInfoByRefreshToken(refreshToken),
				client,
				callback
			)
		}, expires - Date.now() - 60000, client, info.refresh_token, callback)
		// Callback
		callback(info.id_token, expires, JSON.parse(atob(info["profile_info"])))
	}
}


export const ADB2C = {

	persistence : {

		async getRefreshToken() {
			try {
				return JSON.parse(localStorage.getItem(ADB2C_STATE_KEY))
			}
			catch(e) {
				console.debug("[AAD B2C] Refresh token not found in storage.")
				return null
			}
		},

		async saveRefreshToken(refreshToken, expires) {
			localStorage.setItem(ADB2C_STATE_KEY, JSON.stringify({
				"refreshToken": refreshToken,
				"expires": expires
			}))
		},

		async clear() {
			localStorage.removeItem(ADB2C_STATE_KEY)
		}

	}

}