import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, OnInit, OnDestroy, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { TokenDetails } from './models/auth/token-details.type';
import { Theme } from './models/cube/theme.type';
import { AuthenticationService } from './services/authentication.service';
import { CubeService } from './services/cube/cube.service';
import { TokenService } from './services/token.service';
import { AuthActions } from './store/actions/auth.actions';
import { selectTokenDetails, selectUserTenants } from './store/selectors/auth.selectors';
import { selectTheme } from './store/selectors/system.selectors';
import { AppState } from './store/states/app.state';

import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'cube-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [],
})
export class AppComponent implements OnInit, OnDestroy {
  isDarkTheme: boolean;

  private subscriptions: Subscription[] = [];
  private readonly authService: AuthenticationService = inject(AuthenticationService);
  private readonly tokenService: TokenService = inject(TokenService);

  constructor(
    private overlayContainer: OverlayContainer,
    private readonly store: Store<AppState>
  ) {
    this.store.dispatch(AuthActions.login());

    this.authService.token.pipe(
      filter((token: string | undefined): token is string => !!token)).subscribe(token => {
      const uniqueId = this.tokenService.getUniqueId(token);
      this.store.dispatch(AuthActions.setTokenDetails({tokenDetails: {idToken: token, uniqueId} as TokenDetails}))})

      this.store
      .select(selectTokenDetails)
      .pipe(
        filter((tokenDetails: TokenDetails | undefined): tokenDetails is TokenDetails => !!tokenDetails),
        concatLatestFrom(() => this.store.select(selectUserTenants)),
        takeUntilDestroyed()
      )
      .subscribe({
        next: ([tokenDetails, tenants]) => {
          if (!tenants.length) {
            this.store.dispatch(AuthActions.getUserTenants({ uniqueId: tokenDetails.uniqueId }));
          }
        },
      });
  }

  ngOnInit(): void {
    this.setEventListeners();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  getRouteAnimation(outlet) {
    return outlet.activatedRouteData.animation;
  }

  private setEventListeners(): void {
    // Theme switching
    this.store
      .select<Theme>(selectTheme)
      .pipe(map((theme: Theme): boolean => theme === 'dark'))
      .subscribe({
        next: (isDark: boolean) => {
          this.isDarkTheme = isDark;
          this.overlayContainer
            .getContainerElement()
            .classList.remove(isDark ? 'light-theme' : 'dark-theme');
          this.overlayContainer
            .getContainerElement()
            .classList.add(!isDark ? 'light-theme' : 'dark-theme');
        },
      });
  }
}
