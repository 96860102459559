import { Injectable } from '@angular/core';
import {jwtDecode} from 'jwt-decode';

interface DecodedToken {
  oid?: string; // Object ID
  sub?: string; // Subject
  [key: string]: any;
}

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor() { }

  decodeToken(token: string): DecodedToken | null {
    try {
      return jwtDecode(token);
    } catch (error) {
      console.error('Error decoding token:', error);
      return null;
    }
  }

  getUniqueId(token: string): string {
    const decodedToken = this.decodeToken(token);
    if (decodedToken) {
      return decodedToken.oid || decodedToken.sub || '';
    }
    return '';
  }
}